@use 'sass:color';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'src/assets/forbidden';

:root {
  --tw-clr-primary: 229, 229, 229;
  --clr-primary: rgb(var(--tw-clr-primary));

  --tw-clr-secondary: 163, 163, 163;
  --clr-secondary: rgb(var(--tw-clr-secondary));

  --tw-clr-tertiary: 3, 113, 156;
  --clr-tertiary: rgb(var(--tw-clr-tertiary));
}

@layer base {
  html {
    @apply h-full
  }
  body {
    @apply font-primary text-sm md:text-base text-tertiary h-full bg-primary;
  }

  /* inter var - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100 900;
    font-display: optional;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
  }

  h1 {
    @apply font-primary text-2xl font-bold md:text-4xl;
  }

  h2 {
    @apply font-primary text-xl font-bold md:text-3xl;
  }

  h3 {
    @apply font-primary text-lg font-bold md:text-2xl;
  }

  h4 {
    @apply font-primary text-base font-bold md:text-lg;
  }

  .circles{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }

  .circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;

  }

  .circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }


  .circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }

  .circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }

  .circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }

  .circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }

  .circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  .circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }

  .circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  .circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  .circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }

  @keyframes animate {

    0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }

    100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }
  }

  .button {
    border: none;
    @apply text-white;
    @apply py-2 px-4 rounded-md;
    background: var(--clr-tertiary);

    &:hover {
      $complement: complement(#03719C);
      background: $complement;
      transform: scale(1.02);
    }
  }
}
